import React from 'react';
import MarkdownPage from '../../markdownPage';
import Breadcrumbs from '../../../UI/Breadcrumbs';
import { getLanguage } from '../../../Language/Language';
import { getString } from '../../../Language/LanguageStrings';

const Component = ({ component }) => {
    const language = getLanguage();
    const mdPath = 'Radio/component/' + language + '/' + component + '.md';

    console.log('mdPath: ' + mdPath);

    return (
        <article className="main-page">
            <Breadcrumbs separator=' > ' path="radio/component">
                {['radio', getString('components')]}
            </Breadcrumbs>
            <MarkdownPage md={mdPath} />
        </article>
    )
}

export default Component;
